import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, EMPTY, catchError, tap, from, map } from 'rxjs';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { DataState, LoadingState, User } from '@arivisHub/shared/domain/entities';
import { KeycloakProfile } from 'keycloak-js';

@Injectable({
    providedIn: 'root',
})
export class UserFacadeService {
    private userDataState$$ = new BehaviorSubject<DataState<User>>(new DataState<User>({ loadingState: LoadingState.INIT }));
    private keycloakService = inject(KeycloakService);

    get userDataState$() {
        return this.userDataState$$.asObservable();
    }

    constructor() {
        this.initEventListener();
    }

    loadUser() {
        this.userDataState$$.next(new DataState<User>({ loadingState: LoadingState.LOADING }));
        from(this.keycloakService.loadUserProfile())
            .pipe(
                map((keycloakProfile: KeycloakProfile) => {
                    const user: User = new User();
                    {
                        user.id = keycloakProfile.id;
                        user.firstName = keycloakProfile.firstName as string;
                        user.lastName = keycloakProfile.lastName as string;
                        user.email = keycloakProfile.email as string;
                    }
                    return user;
                }),
                tap((user: User) => {
                    this.userDataState$$.next(new DataState<User>({ loadingState: LoadingState.LOADED, data: user }));
                }),
                catchError(() => {
                    this.userDataState$$.next(
                        new DataState<User>({ loadingState: LoadingState.LOADED, errorState: { errorMsg: 'Loading user information failed.' } }),
                    );
                    return EMPTY;
                }),
            )
            .subscribe();
    }

    logout() {
        from(this.keycloakService.logout())
            .pipe(
                catchError(err => {
                    console.log(err);
                    return EMPTY;
                }),
            )
            .subscribe();
    }

    initEventListener() {
        this.keycloakService.keycloakEvents$.subscribe({
            next: event => {
                if (event.type == KeycloakEventType.OnTokenExpired) {
                    from(this.keycloakService.updateToken(20)).subscribe();
                }
            },
        });
    }
}
