import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { FeatureFlagsService } from '@arivisHub/feature-flags/domain';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected override readonly router: Router,
        protected readonly keycloak: KeycloakService,
        protected readonly featureFlagsService: FeatureFlagsService,
    ) {
        super(router, keycloak);
    }

    async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthFeatureActivated: boolean = this.featureFlagsService.isFeatureActivated('auth');
        if (isAuthFeatureActivated && !this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url,
            });
        }
        return this.authenticated || !isAuthFeatureActivated;
    }

    override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return this.featureFlagsService.isFeatureActivated('auth') ? super.canActivate(route, state) : Promise.resolve(true);
    }
}
