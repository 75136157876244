import { Injectable } from '@angular/core';
import { FeatureFlagItem } from '../entities/feature-flag-item.model';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService {
    get allFeatureFlags(): FeatureFlagItem[] {
        return [
            {
                identifier: 'tags',
                label: 'Tags',
                defaultSetting: false,
            },
            {
                identifier: 'auth',
                label: 'Auth',
                defaultSetting: false,
            },
        ];
    }

    static readonly plainJsonContentType = { 'Content-Type': 'application/json' };
    private readonly localStoragePrefix = 'feature_flag';
    private readonly separator = '_';

    setFeatureFlag(featureIdentifier: string, state: boolean): void {
        localStorage.setItem(this.getLocalStorageFeatureKey(featureIdentifier), state.toString());
    }

    toggleFeatureFlag(featureIdentifier: string): void {
        const currentFeatureFlagState = this.isFeatureActivated(featureIdentifier);
        this.setFeatureFlag(featureIdentifier, !currentFeatureFlagState);
    }

    isFeatureActivated(featureIdentifier: string): boolean {
        const storedValue = localStorage.getItem(this.getLocalStorageFeatureKey(featureIdentifier));
        if (storedValue != null) {
            return storedValue === 'true';
        }

        const feature: FeatureFlagItem | undefined = this.getFeatureObject(featureIdentifier);
        if (feature != null) {
            return feature.defaultSetting;
        }

        return false;
    }

    private getLocalStorageFeatureKey(featureIdentifier: string): string {
        return this.localStoragePrefix + this.separator + featureIdentifier;
    }

    private getFeatureObject(featureIdentifier: string): FeatureFlagItem | undefined {
        return this.allFeatureFlags.find((feature: FeatureFlagItem) => feature.identifier === featureIdentifier);
    }
}
