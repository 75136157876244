import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { FeatureFlagsFacadeService } from '@arivisHub/feature-flags/api';
import { environment } from 'environments';
import { KeycloakService } from 'keycloak-angular';
import { appRoutes } from './app.routes';

const initializeKeycloak = (keycloak: KeycloakService, featureFlagsFacadeService: FeatureFlagsFacadeService) => {
    return () =>
        featureFlagsFacadeService.isFeatureActivated('auth') &&
        keycloak.init({
            config: {
                url: environment.identityProvider.authIssuerUrl,
                realm: environment.identityProvider.realm,
                clientId: environment.identityProvider.client,
            },
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
            },
            shouldAddToken: request => {
                const { method, url } = request;
                const excludedPaths = ['/assets'];
                return !(method.toUpperCase() === 'GET' && excludedPaths.some(path => url.includes(path)));
            },
        });
};

export const appConfig: ApplicationConfig = {
    providers: [
        KeycloakService,
        { provide: LOCALE_ID, useValue: 'en-US' },
        provideRouter(appRoutes, withComponentInputBinding()),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService, FeatureFlagsFacadeService],
        },
    ],
};
