import { LoadingState } from '../enums';
import { ErrorState } from './error-state.model';

export class DataState<T> {
    loadingState: LoadingState;
    data?: T;
    errorState?: ErrorState;

    constructor(config: { loadingState: LoadingState; data?: T; errorState?: ErrorState }) {
        this.loadingState = config.loadingState;
        this.data = config.data;
        this.errorState = config.errorState;
    }

    isLoading(): boolean {
        return this.loadingState === 'LOADING';
    }

    isLoaded(): boolean {
        return this.loadingState === 'LOADED';
    }

    isInitialData(): boolean {
        return this.loadingState === 'INIT';
    }

    hasError(): boolean {
        return this.errorState != null;
    }
}
