/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { environment } from 'environments';
import { appConfig } from './app/app.config';

import '@zeiss/zui';
import { registerTheme, registerTypefaces, setAppTheme, themeZbdsBase, themeZbdsDark } from '@zeiss/zui';
import '@zeiss/zui-icons';

registerTheme(themeZbdsBase);
registerTheme(themeZbdsDark);

setAppTheme('zbds-dark');

registerTypefaces('/assets/fonts');

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));

console.log(environment.baseUrl);
