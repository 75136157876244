import { AsyncPipe, NgFor } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTING_DOCUMENTS, RoutingDefinition } from '@arivisHub/shared/domain/entities';
import { GetZuiEvent, Menubar } from '@zeiss/zui';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Component({
    selector: 'arivis-sidenav',
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [NgFor, AsyncPipe],
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
    private router = inject(Router);
    mainRoutes: RoutingDefinition[] = [ROUTING_DOCUMENTS];

    private selectedRouteId$$ = new BehaviorSubject(this.mainRoutes[0].id);
    get selectedRouteId$(): Observable<string> {
        return this.selectedRouteId$$.asObservable();
    }

    constructor() {
        this.router.events
            .pipe(
                tap(event => {
                    if (event instanceof NavigationEnd) {
                        // We've finished navigating
                        this.mainRoutes.forEach((routeDef: RoutingDefinition) => {
                            if (event.url.substring(1).startsWith(routeDef.route)) {
                                this.selectedRouteId$$.next(routeDef.id);
                            }
                        });
                    }
                }),
            )
            .subscribe();
    }

    navigateTo(event: GetZuiEvent<Menubar, 'SelectedEvent'>): void {
        this.selectedRouteId$$.next(event.detail.value);
        this.router.navigate([this.mainRoutes.find(route => route.id === event.detail.value)?.route]);
    }
}
