import { KeycloakProfile } from 'keycloak-js';

export class User implements KeycloakProfile {
    id?: string;
    email: string;
    firstName: string;
    lastName: string;
    profileImageUrl?: string;

    get initials() {
        return this.firstName.substring(0, 1) + this.lastName.substring(0, 1);
    }
}
