import { Route } from '@angular/router';
import { ROUTING_DOCUMENTS, RoutingDefinition } from '@arivisHub/shared/domain/entities';
import { AuthGuard } from './core/guards/auth.guard';

export const ROUTING_HOME: RoutingDefinition = ROUTING_DOCUMENTS;

export const appRoutes: Route[] = [
    {
        path: 'feature-flags',
        canActivate: [AuthGuard],
        loadChildren: () => import('@arivisHub/feature-flags/feature-feature-flags').then(m => m.featureFlagsRoutes),
    },
    {
        path: ROUTING_DOCUMENTS.route,
        canActivate: [AuthGuard],
        loadChildren: () => import('@arivisHub/data-mgmt/feature-documents').then(m => m.dataMgmtFeatureDocumentsRoutes),
    },
    { path: 'home', pathMatch: 'full', redirectTo: ROUTING_HOME.route },
    { path: '', pathMatch: 'full', redirectTo: ROUTING_HOME.route },
    { path: '**', redirectTo: ROUTING_HOME.route },
];
