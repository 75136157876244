import { AppEnvironment } from './app-environment.enum';
import { AppEnvironmentConfig } from './app-environment-config.model';

export const defaultEnvironment: AppEnvironmentConfig = {
    type: AppEnvironment.Development,
    name: AppEnvironment.Development,
    requestTimeout: 300000,
    baseUrl: window.location.origin,
    landingPageUrl: '',
    identityProvider: {
        authIssuerUrl: 'http://localhost:8080',
        client: 'frontend',
        realm: 'arivisHub',
        signupUrl: '',
    },
};
