<zui-headerbar>
    <zui-zeiss-logo size="s32" slot="icon" (click)="navigateToHome()" class="home-logo"></zui-zeiss-logo>
    <zui-headerbar-product-name product-name="arivis" product-sub-name="Hub" slot="productName"></zui-headerbar-product-name>
    <ng-container *ngIf="userDataState && userDataState.isLoaded(); else userMenuButtonLoading">
        <zui-user-menu-button size="l" slot="userMenuButton" user-name="{{ username }}" (click)="toggleUserMenu.emit()">
            <zui-avatar initials="{{ userDataState.data?.initials }}" slot="avatar"></zui-avatar>
        </zui-user-menu-button>
    </ng-container>
</zui-headerbar>

<ng-template #userMenuButtonLoading>
    <div class="d-flex align-items-center me-4 ps-4 user-menu-skeleton" slot="userMenuButton">
        <zui-skeleton-rectangle class="user-menu-button__skeleton-rectangle"></zui-skeleton-rectangle>
        <zui-skeleton-circle class="user-menu-button__skeleton-circle"></zui-skeleton-circle>
    </div>
</ng-template>
