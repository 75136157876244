import { Injectable, computed, signal } from '@angular/core';
import { LoaderBoxState } from '@arivisHub/shared/domain/entities';

@Injectable({
    providedIn: 'root',
})
export class LoaderBoxService {
    loaderBoxState = computed(() => this._loaderBoxState());

    private _loaderBoxState = signal<LoaderBoxState>({
        isLoaderBoxShown: false,
    });

    showLoaderBox(headline?: string, description?: string): void {
        this._loaderBoxState.set({
            isLoaderBoxShown: true,
            headline,
            description,
        });
    }

    hideLoaderBox(): void {
        this._loaderBoxState.set({
            isLoaderBoxShown: false,
            headline: undefined,
            description: undefined,
        });
    }
}
