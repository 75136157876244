<zui-menubar
    emphasis="selected"
    variant="primary"
    more-menu-label="More"
    [selected]="selectedRouteId$ | async"
    (zui-menubar-selected)="navigateTo($any($event))"
>
    <zui-menubar-nav-item *ngFor="let route of mainRoutes" [value]="route.id">
        <zui-icon-common-home size="s16" slot="icon"></zui-icon-common-home>{{ route.label }}</zui-menubar-nav-item
    >
</zui-menubar>
