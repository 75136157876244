import { AsyncPipe, NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderBoxService, UserFacadeService } from '@arivisHub/shared/domain/logic';
import { LetDirective } from '@ngrx/component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';

@Component({
    selector: 'arivis-root',
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [AsyncPipe, HeaderComponent, NgIf, LetDirective, RouterOutlet, SidenavComponent],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    private loaderBoxService = inject(LoaderBoxService);
    private userFacadeService = inject(UserFacadeService);

    title = 'arivis Hub';
    isUserMenuOpen = false;

    readonly loaderBoxState = this.loaderBoxService.loaderBoxState;
    readonly userDataState$ = this.userFacadeService.userDataState$;

    ngOnInit(): void {
        this.userFacadeService.loadUser();
    }

    toggleUserMenu(): void {
        this.isUserMenuOpen = !this.isUserMenuOpen;
    }

    logout(): void {
        this.userFacadeService.logout();
    }
}
