<div *ngIf="loaderBoxState().isLoaderBoxShown" class="loader-box-wrapper">
    <zui-loader-box [headline]="loaderBoxState().headline" [description]="loaderBoxState().description" class="loader-box"></zui-loader-box>
</div>
<div *ngrxLet="userDataState$ as userDataState">
    <header class="stick-top"><arivis-header (toggleUserMenu)="toggleUserMenu()" [userDataState]="userDataState"></arivis-header></header>
    <zui-menu *ngIf="isUserMenuOpen && userDataState.data" class="user-menu">
        <zui-title-stack size="xs" class="p-4">
            <h2>{{ userDataState.data.firstName }} {{ userDataState.data.lastName }}</h2>
            <zui-title-stack-meta-item slot="meta">{{ userDataState.data.email }}</zui-title-stack-meta-item>
        </zui-title-stack>
        <zui-menu-divider></zui-menu-divider>
        <zui-menu-item (click)="logout()"><zui-icon-common-logout slot="icon"></zui-icon-common-logout> Logout</zui-menu-item>
    </zui-menu>
    <div class="content-wrapper">
        <div class="sidebar">
            <arivis-sidenav></arivis-sidenav>
        </div>
        <main role="main" class="h-100 overflow-hidden">
            <router-outlet></router-outlet>
        </main>
    </div>
</div>
