import { RoutingDefinition } from './models';

// date & time format
export const DATE_FORMAT_DATE_NUMERIC_ONLY = 'dd/MM/yyyy'; // 23/04/2023
export const DATE_FORMAT_DATE_SHORT_MONTH = 'MMM d, yyyy'; // Apr 23, 2023
export const DATE_FORMAT_DATE_LONG_MONTH = 'MMMM d, yyyy'; // April 23,  2023
export const DATE_FORMAT_DATE_LONG_MONTH_IN_TEXT = 'MMMM d yyyy'; // April 23 2023
export const DATE_FORMAT_DATE = DATE_FORMAT_DATE_SHORT_MONTH;
export const DATE_FORMAT_TIME = 'HH:mm:ss'; // 23:15:40

// top level routes
export const ROUTING_DOCUMENTS: RoutingDefinition = { id: '1', route: 'documents', label: 'Projects & Data' };
