import { Injectable, inject } from '@angular/core';
import { FeatureFlagsService } from '@arivisHub/feature-flags/domain';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsFacadeService {
    private featureFlagsService = inject(FeatureFlagsService);

    isFeatureActivated(featureIdentifier: string): boolean {
        return this.featureFlagsService.isFeatureActivated(featureIdentifier);
    }
}
