import { NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DataState, User } from '@arivisHub/shared/domain/entities';
import { ROUTING_HOME } from '../../app.routes';

@Component({
    selector: 'arivis-header',
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [NgIf],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Input() userDataState?: DataState<User>;
    @Output() toggleUserMenu = new EventEmitter<boolean>();

    get username(): string {
        return `${this.userDataState?.data?.firstName} ${this.userDataState?.data?.lastName}`;
    }

    private router = inject(Router);

    navigateToHome(): void {
        this.router.navigate([ROUTING_HOME.route]);
    }
}
